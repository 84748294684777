html {
    background-color: black;
}


.clicka {
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 2px solid #555555;
  position: auto;
  top: 300px;
  left: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  
}

.clicka:hover {
  background-color: #555555;
  color: white;
}

.infoBox {
  position: auto;
  top: 300px;
  left: 900px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

